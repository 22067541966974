<template>
    <v-container class="amp-module-page">
        <amp-row-first>
            <template #input>
                <status-chip
                    v-if="blogpost.id && !isEditable"
                    class="mb-3"
                    :class="{
                        'amp-module-element-hoisted':
                            $vuetify.breakpoint.mdAndUp
                    }"
                    :large="$vuetify.breakpoint.mdAndUp"
                    :status="status"
                />

                <v-skeleton-loader
                    v-if="isLoading"
                    loading
                    type="heading"
                    class="pt-2 pb-1"
                />
                <span v-else>
                    <a-alert v-if="isRejected">
                        Your AMP has been declined for distribution to MSN.
                        Please enhance the quality of your blog post or modify
                        your distribution publishing settings to exclude MSN,
                        and then resubmit. {{ rejectionReason }}
                    </a-alert>
                    <h2 class="font-weight-medium text-justify">
                        {{ blogpost.headline || '&#160;' }}
                    </h2>
                </span>

                <v-divider class="my-4" />
            </template>
        </amp-row-first>

        <amp-row>
            <template #input>
                <v-skeleton-loader
                    v-if="isLoading"
                    loading
                    type="list-item-three-line"
                    class="mx-n4"
                />
                <!-- eslint-disable vue/no-v-html -->
                <h3
                    v-else
                    class="font-weight-regular text-justify"
                    v-html="blogpost.summary || '&#160;'"
                ></h3>
                <!-- eslint-enable vue/no-v-html -->
            </template>
        </amp-row>

        <amp-row>
            <template #input>
                <v-skeleton-loader v-if="isLoading" type="image" />
                <div v-else class="py-8">
                    <v-img
                        :src="featuredMedia"
                        contain
                        max-height="60vh"
                        position="left center"
                    />
                </div>
            </template>
        </amp-row>

        <amp-row-last>
            <template #input>
                <v-skeleton-loader
                    v-if="isLoading"
                    loading
                    type="paragraph@3"
                    class="py-2"
                />
                <!-- eslint-disable vue/no-v-html -->
                <div
                    v-else
                    class="blog-post-content font-weight-regular text-justify"
                    v-html="blogPostContent || '&#160;'"
                ></div>
                <!-- eslint-enable vue/no-v-html -->
                <video-placeholder
                    v-if="canSeeEmbedPlaceholder && !hasRealEmbedVideo"
                    :image-url="featuredMedia"
                />
            </template>
        </amp-row-last>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';

import { Endpoint } from '@/mixins';
import { InjectReactive, Watch } from '@/utils/decorators';

import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast
} from '@/components/AmpModule/AmpPage';

import { AAlert } from '@/components/AAlert';

import { StatusChip } from '@/components/StatusChip';

import type { AmpModules } from '@/types/Announcement';
import { BlogPostStatus, type BlogPost as IBlogPost } from '@/types/BlogPost';
import type { ModuleLink } from '@/types/ModuleLink';
import { VideoPlaceholder } from '@/components/VideoPlaceholder';

@Component({
    components: {
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        StatusChip,
        AAlert,
        VideoPlaceholder
    },
    computed: {
        ...mapGetters('user', ['isClient'])
    }
})
export default class BlogPost extends mixins(Endpoint) {
    isClient!: boolean;

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @Watch('modules')
    onModulesChanged() {
        this.onMounted();
    }

    blogpost: Partial<IBlogPost> = {};

    isMsn = false;

    endpoint = '/blog_posts/review';

    get link(): ModuleLink[] {
        return [
            {
                type: 'primary',
                label: 'Edit',
                to: this.editLink,
                warn: this.isClient && this.isMsn
            }
        ];
    }

    get moduleId() {
        return this.modules?.blog_post_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get editLink() {
        return `/announcements/edit/${this.announcementId}/blog-post`;
    }

    get isEditable() {
        return this.blogpost.is_editable;
    }

    get status() {
        return this.blogpost.status_string;
    }

    get isLive() {
        return this.blogpost.is_live;
    }

    get isRejected() {
        return this.blogpost.status === BlogPostStatus.Rejected;
    }

    get rejectionReason() {
        if (this.blogpost.approval_message) {
            return `Reason: ${this.blogpost.approval_message}`;
        }

        return null;
    }

    get featuredMedia() {
        const resource = this.blogpost.media_resources?.find(
            resource => resource.featured
        );

        if (resource) {
            return (
                resource.media_file.original_url ||
                resource.media_file.relative_filename ||
                resource.media_file.thumbnail_filename
            );
        }

        return '';
    }

    get hasRealEmbedVideo() {
        return this.blogpost?.content?.includes('youtube.com/embed/');
    }

    get canSeeEmbedPlaceholder() {
        return Boolean(this.blogpost.user?.has_ai_features) && !this.isLive;
    }

    get blogPostContent() {
        return this.blogpost?.content?.replace('{video_pr:link}', '');
    }

    onMounted() {
        if (this.moduleId) {
            this.load();
        } else {
            this.edit();
        }
    }

    onData(data: {
        blogPost: IBlogPost;
        isMsn: boolean;
        isLongFormEditorAvailable: boolean;
    }) {
        this.blogpost = data.blogPost;

        if (data.isLongFormEditorAvailable && !this.blogpost.content) {
            this.$store.dispatch(
                'notification/info',
                'Content has not been generated. Please Review and submit.'
            );
            this.edit();
            return;
        }

        this.isMsn = data.isMsn || false;

        this.emitLinks();
    }

    emitLinks() {
        this.$emit('links', this.isEditable ? this.link : []);
    }

    edit() {
        return this.$router.push(this.editLink);
    }
}
</script>

<style lang="scss">
.blog-post-content {
    h1,
    h2,
    h3 {
        padding-top: 0.375em;
        padding-bottom: 0.375em;
    }

    ul,
    ol {
        margin-bottom: 0.75em !important;
    }

    p {
        margin-bottom: 0.75em;
    }
}
.video-placeholder {
    width: 100%;
    height: 300px;
    background: rgba(0, 0, 0, 0.05);
}
</style>
